.modal {
  color: var(--site-grey);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Ensure modal appears above other content */
}

.modal-content {
  position: relative; /* Enable absolute positioning for the close button */
  background: var(--site-white);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 90%;
  z-index: 11;
}

.modal-content h3 {
  margin-bottom: 10px;
}

.modal-content input {
  width: 80%;
  padding: 10px;
  margin-bottom: 15px;
  font-family: "Poppins", sans-serif;
}

.modal-content button:first-child {
  background-color: var(--primary-brand-color);
  color: var(--site-white);
}

/* Close button styles */
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--site-grey);
}

input.invalid {
  border: 1px solid var(--error-color);
}

.error-message {
  color: var(--error-color);
  font-size: 0.9em;
}
