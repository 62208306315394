.notFound {
  background-color: var(--site-white);
  display: flex;
  align-items: center;
  height: 100vh;
  margin: 0;
  text-align: center;
  flex-direction: column;
  padding: 20px;
}

.notFound h2 {
  font-size: 3rem;
  margin: 0;
  color: var(--supplementary-brand-color);
}

.notFound p {
  font-size: 1.2rem;
  color: var(--supplementary-brand-color);
}

.notFound .whale {
  font-size: 4rem;
  margin: 15px 0;
  color: var(--supplementary-brand-color);
}

.notFound .button {
  background-color: var(--supplementary-brand-color);
  color: var(--site-white);
  font-size: 1rem;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.notFound .button:hover {
  background-color: #1f7070;
}

.notFound .whale-img {
  width: 120px;
  margin-top: 20px;
}

/* Media query for larger screens (tablets and up) */
@media (min-width: 768px) {
  .notFound h1 {
    font-size: 3rem;
  }

  .notFound p {
    font-size: 1.5rem;
  }

  .notFound .whale {
    font-size: 6rem;
  }

  .notFound .button {
    font-size: 1.2rem;
  }

  .notFound .whale-img {
    width: 150px;
  }
}

/* Media query for larger screens (desktops and up) */
@media (min-width: 1024px) {
  .notFound h1 {
    font-size: 4rem;
  }

  .notFound p {
    font-size: 1.8rem;
  }

  .notFound .whale {
    font-size: 8rem;
  }

  .notFound .button {
    font-size: 1.4rem;
  }

  .notFound .whale-img {
    width: 180px;
  }
}
