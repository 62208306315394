/* Key Features Section */
.key-features {
  padding: 0 20px;
  margin: 20px auto;
  max-width: 900px;
}

.key-features h2 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
}

.key-features ul {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.key-features li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.key-features li svg {
  margin-right: 10px;
  font-size: 1.5rem;
  color: var(--supplementary-brand-color);
}

.key-features li strong {
  font-weight: bold;
  font-size: 1.1rem;
}

.key-features li p {
  margin: 0;
}

.button-container {
  display: flex;
  justify-content: center;
  text-align: center;
}

@media (max-width: 600px) {
  .key-features {
    padding: 10px;
  }

  .key-features li svg {
    font-size: 1.2rem;
  }

  .key-features li strong {
    font-size: 1rem;
  }
}
