.social-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px; /* Space between icons */
  padding: 10px 0;
  border-top: var(--site-white) solid 0.1px;
}

.social-link {
  color: var(--primary-brand-color);
  font-size: 40px;
  text-decoration: none;
}

/* Responsive Adjustments for Larger Screens */
@media (min-width: 768px) {
  .social-link {
    font-size: 45px;
    color: var(--site-grey);
  }

  .social-link:hover {
    color: var(--primary-brand-color);
  }

  .social-container {
    justify-content: flex-start;
    padding-top: 20px;
  }
}
