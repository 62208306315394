.adventure-types-container {
  position: relative; /* Allow absolute positioning of the overlay */
  min-height: 80vh; /* Ensure the section is not too small */
  background-image: url(https://res.cloudinary.com/dinnupyay/image/upload/f_webp/q_auto/v1732613832/ben-phillips_pcl9a5.jpg);
  background-size: cover; /* Cover the entire section */
  padding: 40px 20px;
  overflow: hidden; /* Hide overflow if necessary */
}

.adventure-types-heading h1 {
  padding: 0;
  padding-top: 10px;
  color: var(--site-white);
  text-align: center;
}

.adventure-types {
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.adventure-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--site-white);
}

.adventure-header-container h2 {
  color: var(--site-white);
}

.tour-icon {
  max-width: 20%; /* Responsive logo size */
  margin-right: 0;
  object-fit: contain;
}

.adventure {
  color: var(--site-white);
  padding: 30px;
  padding-top: 20px;
  border-radius: 10px;
  margin: 10px auto;
  max-width: 350px;
  cursor: pointer;
  background-color: rgba(
    0,
    0,
    0,
    0.6
  ); /* Base background color for better readability */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
}

.swim {
  background-color: var(--supplementary-brand-color-transparent);
}

.see {
  background-color: var(--secondary-brand-color-transparent);
}

.adventure h2 {
  margin-top: 10px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
  padding-top: 10px;
}

.adventure p {
  font-size: 14px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: var(--site-white);
  margin: 10px 0;
}

/* Responsive Adjustments for Larger Screens */
@media (min-width: 768px) {
  .adventure-types {
    display: flex;
    flex-direction: row;
  }

  .adventure-types-heading h1 {
    padding-top: 20px;
  }

  .adventure:hover {
    background-color: rgba(
      0,
      0,
      0,
      0.8
    ); /* Slightly darker on hover for readability */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); /* Light shadow for depth */
  }

  .adventure h2 {
    font-size: 2rem;
    padding-top: 20px;
  }

  .adventure p {
    font-size: 1rem;
  }

  .adventure {
    max-width: 90%;
  }

  .left {
    margin-right: 20px;
  }

  .tour-icon {
    margin-right: 20px;
  }
}

@media (min-width: 1024px) {
  .adventure h2 {
    font-size: 3rem;
  }

  .adventure p {
    font-size: 1.2rem;
  }
}
