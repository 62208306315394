.newsletter-container {
  color: var(--site-grey);
  padding-bottom: 20px; /* Padding around the container */
  border-radius: 8px; /* Rounded corners */
  text-align: center; /* Center the text */
  max-width: 300px; /* Maximum width */
  margin: 0px auto; /* Center on the page */
  text-align: left;
}

.newsletter-container h3 {
  margin-bottom: 15px;
}

.newsletter-container form {
  display: flex; /* Flexbox for form layout */
  flex-direction: column; /* Stack inputs vertically */
}

.newsletter-container input {
  padding: 10px; /* Padding inside the input */
  border: none; /* No border */
  border-radius: 4px; /* Rounded corners */
  margin-bottom: 10px; /* Space between input and button */
  font-family: "Poppins", sans-serif;
}

@media (min-width: 768px) {
  .newsletter-container {
    max-width: 600px; /* Maximum width */
  }
}

/* Container for the entire modal */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: auto; /* Allow scrolling if needed */
}

/* Pop-up content container */
.popup-content {
  position: relative; /* Relative positioning for the close button */
  background-color: var(--site-white);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  width: 100%;
  animation: slideIn 0.5s ease-out;
}

/* Title and message styling */
.popup-content h2 {
  font-size: 24px;
  color: var(--primary-brand-color);
}

.popup-content p {
  font-size: 16px;
  color: var(--site-grey);
  margin-bottom: 20px;
}

/* Form container */
.popup-content form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Input fields (for name and email) */
.popup-content input[type="text"],
.popup-content input[type="email"] {
  padding: 10px;
  width: 80%;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
}

/* Subscribe button */
.subscribe-btn {
  padding: 10px 20px;
  background-color: var(--primary-brand-color);
  color: var(--site-white);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}

.subscribe-btn:hover {
  background-color: var(--accent-color);
}

/* Close button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  border: none;
  background: none;
  color: var(--site-black);
  cursor: pointer;
}

.close-btn:hover {
  color: var(--error-color);
}

/* Animation for slide-in effect */
@keyframes slideIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

/* Responsiveness for larger screens */
@media (min-width: 768px) {
  .popup-content {
    max-width: 600px;
  }
}
