.navbar {
  background-color: var(--site-black);
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  color: var(--site-white);
}

.nav-logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.nav-link p {
  color: var(--site-white);
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
}

.nav-logo {
  width: 42px;
  height: 42px;
}

.hamburger {
  display: block;
  font-size: 30px;
  cursor: pointer;
  margin-top: 8px;
}

.nav-menu {
  display: none;
  position: absolute;
  top: 60px;
  right: 0;
  width: 100%;
  background-color: var(--site-black);
  text-align: center;
  flex-direction: column;
  z-index: 100;
  list-style: none;
}

.nav-menu.active {
  display: flex;
  margin: 0;
}

.nav-item {
  margin: 20px 0;
  padding-top: 10px;
  padding-bottom: 10px;
  text-decoration: none;
  color: var(--site-white);
}

/* Base styles for nav-links */
.nav-link,
.nav-link-dropdown {
  color: var(--site-white);
  text-decoration: none;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

/* Main navigation hover effect */
.nav-link:hover {
  color: var(
    --supplementary-brand-color
  ); /* Change nav-link color to supplementary brand color */
}

/* Dropdown navigation hover effect */
.nav-link-dropdown:hover {
  background-color: var(
    --supplementary-brand-color
  ); /* Change background color on hover */
  color: var(--site-white); /* Keep text white on hover */
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

/* Styles for larger screens (above 1024px) */
@media (min-width: 1024px) {
  .hamburger {
    display: none;
  }

  .nav-menu {
    display: flex;
    position: relative;
    flex-direction: row;
    background-color: transparent;
    width: auto;
    align-items: center;
    margin-right: 0;
    top: 0;
  }

  .nav-menu.active {
    display: flex;
    background-color: transparent;
  }

  .nav-item {
    margin: 0 10px;
    padding-bottom: 0;
    padding-top: 0;
  }

  .nav-link {
    font-size: 18px;
  }

  .nav-link p {
    font-size: 18px;
  }

  /* Dropdown menu styles */
  .dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
    background-color: transparent !important;
    padding-top: 0 !important;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--site-black);
    min-width: 160px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding-left: 0;
    color: var(--site-white); /* Default text color */
  }

  /* Dropdown menu items */
  .dropdown-content li {
    padding: 12px 16px;
    list-style-type: none;
    color: var(--site-white); /* Ensure dropdown items text stays white */
    background-color: var(--site-black); /* Ensure background is black */
  }

  .dropdown-content li:hover {
    background-color: var(
      --supplementary-brand-color
    ) !important; /* Change background color on hover */
    color: var(--site-white) !important; /* Ensure text stays white on hover */
  }

  .nav-menu .dropdown-content li {
    color: var(--site-white) !important; /* Make dropdown items' text white */
    background-color: var(
      --site-black
    ) !important; /* Keep dropdown items' background black */
  }

  /* Hover state for dropdown items */
  .nav-menu .dropdown-content li:hover {
    background-color: var(
      --supplementary-brand-color
    ) !important; /* Highlight with supplementary color */
    color: var(--site-white) !important; /* Ensure text remains white */
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }
}
