/* Base Styles (Mobile First) */
.all-tours-container {
  color: var(--site-white);
}

.message {
  padding: 0 20px;
  text-decoration: none;
}

.message a {
  color: var(--accent-color);
}

.filters {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
  padding: 10px 0;
  border-bottom: 1px solid var(--site-white);
}

.filters label {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-direction: column; /* Stack label and select vertically */
  font-weight: bold;
  color: var(--site-white);
}

.filters select {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid var(--site-white);
  margin-top: 5px;
  width: 100%;
  max-width: 200px; /* Optional: You can limit the width of select inputs */
}

/* To handle text on one line for mobile */
.filters select {
  max-width: 90vw;
}

/* Mobile layout - Whale Type and Adventure Type side by side */
@media (max-width: 767px) {
  .filters {
    flex-direction: column; /* Stack filters vertically on mobile */
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  .filters .top-filters {
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: center;
  }

  .filters label {
    flex-direction: row; /* Align label and select in a row */
    gap: 10px;
  }

  .filters select {
    max-width: 90vw; /* Ensure select inputs fit within mobile width */
  }

  .sort {
    width: 80vw;
  }

  .sort label {
    margin-right: 15px;
  }
}

/* Desktop Layout */
@media (min-width: 768px) {
  .top-filters {
    display: flex;
  }
  .filters {
    flex-direction: row; /* Keep filters side by side */
    justify-content: flex-start; /* Align filters to the left */
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .filters label {
    flex-direction: row; /* Align label and select horizontally */
    margin-right: 15px;
    align-items: center; /* Align text and select input */
  }

  .filters select {
    width: auto; /* Let the select inputs take their natural width */
    max-width: 200px;
  }

  .sort {
    margin-left: auto; /* Push the Sort filter to the right */
  }
}
