.why-book-section {
  width: 100%;
  padding: 30px 20px;
  background-color: var(--site-white);
  box-sizing: border-box;
}

.why-book-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
  background-color: var(--site-white);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 30px;
}

.why-book-container h2 {
  color: var(--primary-brand-color);
  margin-bottom: 20px;
}

.why-book-container p {
  line-height: 1.8;
  color: var(--site-grey);
  max-width: 800px;
  margin: 0 auto 20px;
}

.why-book-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  list-style: none;
  padding: 0;
}

.why-book-list li {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  color: var(--site-grey);
  text-align: start;
}

.why-book-list li svg {
  flex-shrink: 0; /* Prevent icons from resizing */
}

.ethics-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 30px;
  background: linear-gradient(
    to bottom,
    rgba(0, 90, 125, 0.6) 0%,
    rgba(0, 90, 125, 0.8) 50%,
    #005a7d 100%
  );
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  color: var(--site-white);
  text-align: center;
  box-sizing: border-box;
}

.ethics-section h2 {
  color: var(--site-white);
  margin-bottom: 20px;
}

.ethics-section p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: var(--site-white);
  margin-bottom: 30px;
  max-width: 800px;
  margin: 0 auto;
}

.ethics-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  list-style: none;
  padding: 0;
}

.ethics-list li {
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  color: var(--site-white);
  text-align: center;
}

.ethics-list li svg {
  flex-shrink: 0; /* Prevent icons from resizing */
}

/* Responsive Styling */
@media (min-width: 768px) {
  .why-book-container,
  .ethics-section {
    padding: 40px;
  }

  .why-book-list li {
    text-align: center;
  }

  .why-book-container p,
  .ethics-section p {
    font-size: 1.2rem;
  }

  .why-book-container p {
    font-size: 1.1rem;
    text-align: center;
  }

  .why-book-list,
  .ethics-list {
    gap: 20px;
  }
}

@media (min-width: 1024px) {
  .why-book-container,
  .ethics-section {
    padding: 50px;
  }

  .why-book-container h2,
  .ethics-section h2 {
    font-size: 2.5rem;
    text-align: center;
  }

  .why-book-container p,
  .ethics-section p {
    font-size: 1.3rem;
  }

  .why-book-list li,
  .ethics-list li {
    font-size: 1.2rem;
  }
}
