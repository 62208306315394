.hero {
  position: relative;
  overflow: hidden;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-img-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.hero-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: linear-gradient(
    to bottom,
    rgba(2, 19, 30, 0.4) 0%,
    rgba(2, 19, 30, 0.6) 50%,
    var(--site-black) 100%
  );
  z-index: 2;
  padding: 0;
}

.hero-logo-container {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 50%; /* Logo size adjustment for mobile */
}

.hero-logo-icon {
  max-width: 80px;
}

.hero-content {
  text-align: center;
  color: var(--site-white);
  z-index: 2;
  margin-top: 55%;
}

.hero h1 {
  font-size: 1.9rem;
  margin-bottom: 8px;
  font-weight: 700;
  text-align: left;
  padding-left: 10px;
  max-width: 95%;
}

.hero-button-container {
  display: flex;
  justify-content: center;
  width: 95%;
  margin: 10px;
}

/* Responsive adjustments for larger screens */
@media (min-width: 600px) {
  .hero {
    min-height: 70vh;
  }

  .hero-logo-container {
    top: 20px; /* Slightly adjusted for larger screens */
  }

  .hero h1 {
    font-size: 2.5rem; /* Larger text size for tablets */
    text-align: center;
    padding: 0;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .hero {
    min-height: 80vh; /* Adjusting for larger screens */
  }

  .hero-content {
    margin-top: -10%;
    margin: 40px;
  }

  .hero-logo-container {
    top: 25px;
  }

  .hero-button-container {
    margin: 10px;
  }
}

@media (min-width: 1024px) {
  .hero {
    min-height: 90vh; /* Adjusting for larger screens */
  }

  .hero h1 {
    font-size: 3rem;
  }
}
