.icon-container {
  display: flex;
  justify-content: flex-end;
}

.icon-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  color: var(--site-white);
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  margin-bottom: 8px;
  font-size: 12px;
}

.icon-wrapper:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

/* Responsive Adjustments for Larger Screens */
@media (min-width: 768px) {
  .icon-container {
    justify-content: flex-start;
  }
}
