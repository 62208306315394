.testimonials {
  padding: 2rem;
  background-color: #f9f9f9;
}

.testimonials h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #333; /* Neutral dark for contrast */
}

.testimonial-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem; /* Space between cards */
}

.testimonial-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1.5rem;
  max-width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.quote {
  font-style: italic;
  font-size: 1.1rem;
  color: #555;
}

.author {
  margin-top: 1rem;
  text-align: right;
  font-weight: bold;
  color: #66b2b2; /* Teal color from your palette */
}
