/* Container styling */
.container {
  min-height: 80vh;
  padding: 20px;
  padding-top: 10px;
  margin: 20px;
  background-color: var(--site-white);
  border-radius: 8px;
}

.container h1 {
  padding-top: 20px;
  font-size: 1.8rem;
  color: var(--site-grey);
  text-align: center;
}

.blog-content h2 {
  font-size: 1.5rem;
}

/* Blog Preview Container */
.blog-preview-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: var(--site-white);
}

/* Header Styling */
.blog-preview-container h1 {
  font-size: 2rem;
  color: var(--site-grey);
  text-align: center;
  margin-bottom: 20px;
}

/* Search Bar Styling */
.search-container {
  text-align: center;
  margin-bottom: 30px;
}

.search-bar {
  padding: 10px;
  width: 80%;
  max-width: 400px;
  border: 2px solid var(--site-grey);
  border-radius: 8px;
  font-size: 1rem;
}

.search-bar:focus {
  outline: none;
  border-color: var(--secondary-brand-color);
}

/* Social media share container */
.social-share {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 20px;
}

/* Blog Post Grid */
.blog-cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding-bottom: 20px;
}

/* Blog Post Image */
.blog-image {
  margin-top: 20px;
  width: 100%;
  object-fit: cover;
  height: 150px;
  border-radius: 8px;
}

/* Individual Blog Cards */
.blog-card {
  background-color: var(--site-white);
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Blog Card Image */
.blog-card-image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
}

/* Blog Card Content */
.blog-card-body {
  padding: 15px;
}

.blog-card-body h2 {
  font-size: 1.5rem;
  color: var(--site-grey);
  margin-bottom: 10px;
}

.blog-excerpt {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Link styling for the "Read More" */
.read-more {
  text-decoration: none;
  color: var(--secondary-brand-color);
  font-weight: bold;
  display: flex;
  justify-content: end;
}

.read-more:hover {
  text-decoration: underline;
}

/* Post Navigation */
.post-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.nav-arrow {
  background-color: transparent;
  border: none;
  color: var(--primary-brand-color);
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  padding: 10px;
  text-decoration: underline;
}

.nav-arrow:hover {
  color: var(--secondary-brand-color);
}

/* Photo Credit */
.photo-credit {
  font-size: 0.9rem;
  color: #555;
  text-align: center;
  margin-top: 8px;
}

.photographer-link {
  color: #3f729b; /* Instagram-like blue */
  text-decoration: none;
}

.photographer-link:hover {
  text-decoration: underline;
}

/* Error Message Styling */
.error-message {
  color: var(--site-white);
  text-align: center;
  margin: 20px 0;
  font-size: 1.2rem;
  min-height: 80vh;
}

/* Responsive Styles */
@media (min-width: 768px) {
  .container {
    padding: 10px 40px;
    margin: 40px;
  }

  .blog-preview-container {
    padding: 40px;
  }

  /* Blog Post Image */
  .blog-image {
    height: 300px;
  }

  .search-bar {
    width: 60%;
  }

  .blog-cards-grid {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }

  .blog-card-body h2 {
    font-size: 1.8rem;
  }

  .blog-excerpt {
    font-size: 16px;
  }

  .read-more {
    font-size: 1rem;
  }

  /* Social media share container */
  .social-share {
    justify-content: start;
  }

  /* Blog.css */
  .load-more-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .load-more-button button {
    background-color: var(--secondary-brand-color);
    color: white;
    font-size: 0.9rem;
    font-family: "Poppins", sans-serif;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .load-more-button button:hover {
    background-color: #4d9a9a; /* Darker teal for hover */
    transform: translateY(-2px); /* Slight lift effect */
  }

  .load-more-button button:disabled {
    background-color: #ccc; /* Disabled button color */
    cursor: not-allowed;
  }
}

@media (min-width: 1024px) {
  .container {
    padding: 10px 60px;
    margin: 60px;
  }
  .blog-preview-container {
    max-width: 100%;
  }

  .blog-image {
    height: 400px;
  }

  .blog-card-body h2 {
    font-size: 2rem;
  }

  .blog-card-image img {
    height: 250px;
  }
}
