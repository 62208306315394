/* FAQ Section */
.faq-section {
  margin: 20px 15px;
  background-color: var(--primary-brand-color);
  padding: 15px;
  color: var(--site-white);
  border-radius: 8px;
}

.faq-section h3 {
  font-size: 1.25rem;
  margin-bottom: 10px;
  font-weight: bold;
}

.faq-section p {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 15px;
}

.faq-section ul {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 15px;
}

.faq-section li {
  font-size: 1rem;
  line-height: 1.6;
}

.faq-question {
  cursor: pointer;
  padding: 12px;
  font-size: 1.1rem;
  border-bottom: 1px solid var(--white);
  transition: background-color 0.3s ease;
}

.faq-answer p {
  margin-top: 0;
}

.faq-answer {
  display: none;
  padding: 10px 12px;
  background-color: var(--primary-brand-color);
  margin-top: 8px;
  border-left: 4px solid var(--site-white);
  border-radius: 4px;
}

.faq-question.open + .faq-answer {
  display: block;
}

.faq-question:hover {
  background-color: var(--secondary-bg-color);
}

.faq-toggle-icon {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--site-white); /* Matches the text color */
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.faq-question.open .faq-toggle-icon {
  transform: rotate(180deg); /* Optional animation for toggle */
}

/* Larger Screen Styles */
@media (min-width: 768px) {
  .faq-section {
    margin: 30px 50px;
    padding: 20px;
  }

  .faq-section h3 {
    font-size: 1.5rem;
  }

  .faq-question {
    font-size: 1.2rem;
  }

  .faq-answer {
    font-size: 1.1rem;
  }

  .key-features {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

@media (min-width: 1024px) {
  .faq-section {
    margin: 40px 80px;
    padding: 30px;
  }

  .faq-section h3 {
    font-size: 1.75rem;
  }

  .faq-question {
    font-size: 1.3rem;
  }

  .faq-answer {
    font-size: 1.15rem;
  }
}
