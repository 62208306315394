.policy-container {
  background-color: var(--site-white);
  color: var(--site-grey);
  padding: 20px;
}

.policy-container h1 {
  color: var(--site-grey);
  font-size: 1.6em;
  padding-bottom: 10px;
}

.policy-container h2 {
  color: var(--site-grey);
  font-size: 1.4em;
  padding-top: 20px;
  margin-bottom: 10px;
}

/* Paragraphs Styling */
.policy-container p,
.policy-container ul,
.policy-container li {
  color: var(--site-grey);
  margin: 10px 0;
}

/* Links Styling */
.policy-container a {
  color: var(--supplementary-color); /* Use the supplementary color for links */
  text-decoration: none;
}

.policy-container a:hover {
  text-decoration: underline;
}

/* List Styling */
.policy-container ul {
  list-style-type: disc;
  padding-left: 20px;
}

.policy-container li {
  margin-bottom: 5px;
}

/* Footer Styling */
.policy-container footer p {
  padding-top: 20px;
  color: var(--site-grey);
  line-height: 0.5;
}

/* Responsive Adjustments */
@media (min-width: 768px) {
  .policy-container {
    background-color: var(--site-white);
    color: var(--site-grey);
    padding: 40px;
    margin: 40px;
    line-height: 1.6;
    border-radius: 8px;
  }
}
