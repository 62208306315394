/* General styles for the landing page (Mobile-first) */
.landing-page-hero {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh; /* Full viewport height */
  width: 100%; /* Ensure full width */
}

.hero-background {
  background-image: url("https://res.cloudinary.com/dinnupyay/image/upload/f_auto,q_auto/v1/awa-tonga-tour/humpback-calf-tonga");
  background-size: cover;
  background-position: center;
  position: absolute; /* Position behind content */
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  z-index: 0; /* Ensure it's behind content */
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(2, 19, 30, 0.2) 0%,
    rgba(2, 19, 30, 0.4) 50%,
    rgba(2, 19, 30, 0.6) 100%
  );
  z-index: 1; /* Above background but below text */
}

.landing-hero-content {
  position: relative;
  text-align: center;
  z-index: 2; /* Ensure content is above the overlay */
  color: var(--site-white);
  padding: 0 20px;
  max-width: 100%;
  width: 100%;
}

.landing-hero-content h1 {
  font-size: 2.9rem; /* Mobile-friendly font size */
  font-weight: bold;
  margin-bottom: 20px;
}

.landing-hero-content p {
  font-size: 1rem;
  line-height: 1.6;
  display: none; /* Hide paragraph on mobile */
}

.hero-landing-page-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  min-width: 320px;
  padding: 0;
}

.hero-landing-page-button .hero-btn {
  font-size: 1.2rem;
  padding: 12px;
  width: 100%;
  border-radius: var(--border-radius);
  background-color: var(--primary-bg-color);
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hero-landing-page-button .hero-btn:hover {
  background-color: darkcyan;
}

/* Desktop styles */
@media (min-width: 768px) {
  .landing-page-hero {
    height: 90vh;
  }
  .landing-hero-content h1 {
    font-size: 4rem;
    margin-bottom: 20px;
  }

  .landing-hero-content p {
    font-size: 1.4rem;
    line-height: 1.8;
    display: block;
    margin-bottom: 20px;
  }

  .hero-landing-page-button .hero-btn {
    font-size: 1.4rem;
    padding: 15px 40px;
  }
}

@media (min-width: 767px) and (min-height: 700px) {
  .hero-landing-page-button {
    width: auto;
    padding: 0;
    left: 50%;
    transform: translateX(-50%);
    bottom: 40px;
  }
}

@media (min-width: 1024px) and (min-height: 700px) {
  .hero-landing-page-button {
    width: auto;
    padding: 0;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100px;
  }
}
