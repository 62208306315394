.what-to-expect {
  padding: 2rem;
  background-color: var(--primary-brand-color);
  text-align: center;
  color: var(--site-white);
  border-radius: 16px;
}

.what-to-expect h2 {
  font-size: 2rem;
  color: var(--site-white);
}

.what-to-expect-intro {
  margin-top: 0;
}

.steps-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
  max-width: 1200px;
  margin: 0 auto;
}

.step-card {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.step-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.large-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: var(--supplementary-brand-color);
}

.step-card h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.step-card p {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
}

.download-container {
  display: flex;
  justify-content: center;
}

@media (min-width: 1024px) {
  .what-to-expect-intro {
    margin-top: 16px;
  }
}

@media (max-width: 768px) {
  .steps-grid {
    grid-template-columns: 1fr;
  }
}
