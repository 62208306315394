.copyright-text {
  text-align: center;
  color: var(--site-white);
  font-family: "Poppins", sans-serif;
  font-style: italic;
  padding-bottom: 10px;
  margin-bottom: 0;
  padding: 10px;
}

.copyright-text a {
  text-align: center;
  color: var(--site-white);
  font-family: "Poppins", sans-serif;
  font-style: italic;
  padding-bottom: 10px;
  margin-bottom: 0;
  text-decoration: none;
}
