/* General button/link styling */
.custom-button {
  display: block; /* Ensure it behaves like a block for both <button> and <a> */
  text-align: center;
  border: none;
  padding: 15px 30px; /* Consistent padding */
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  font-family: "Poppins", sans-serif;
  width: 100%;
  text-decoration: none;
  box-sizing: border-box;
}

/* Add hover effect */
.custom-button:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.link {
  margin-top: 10px;
  max-width: 1100px;
  font-size: 1.3rem;
}

/* Adjust for responsive screens */
@media (min-width: 600px) {
  .custom-button {
    max-width: 400px;
    padding: 15px 30px;
    font-size: 1.2rem;
  }
}

@media (min-width: 768px) {
  .link {
    margin-top: 10px;
    max-width: 1100px;
    font-size: 1.3rem;
  }
}

@media (min-width: 1024px) {
  .custom-button {
    max-width: 500px;
    font-size: 1.3rem;
  }

  .link {
    margin-top: 10px;
    max-width: 1100px;
    font-size: 1.3rem;
  }
}
