.tour-details-container {
  min-height: 80vh;
}

.tour-card {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin: 20px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--site-white);
}

.tour-image {
  width: 100%;
  object-fit: cover;
  height: 200px;
}

.tour-info {
  padding: 20px;
  color: var(--site-grey);
}

.tour-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4px;
}

.section-header {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.symbols {
  max-width: 15%;
  margin-right: 0;
  object-fit: contain;
  border-radius: 8px;
  padding: 4px;
}

.icon {
  min-width: 30%;
  margin-right: 0;
  object-fit: contain;
  border-radius: 8px;
  padding: 4px;
}

.swim {
  background-color: var(--supplementary-brand-color-transparent);
}

.see {
  background-color: var(--secondary-brand-color-transparent);
}

.text {
  color: var(--site-white);
  padding-left: 20px;
}

.tour-price-container {
  display: flex;
  flex-direction: row;
}

.tour-price {
  font-size: 1.2rem;
  font-weight: bold;
  gap: 8px;
}

.price-display {
  display: flex;
  flex-direction: column;
}

.price-display p {
  margin: 0;
}

.current-price {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.tour-price-details {
  margin-left: 4px;
}

.tour-destination {
  text-align: right;
  color: var(--site-grey);
}

.tour-country {
  text-align: right;
  color: var(--site-grey);
}

.tour-destination p {
  margin-top: 0;
}

.tour-location {
  margin-bottom: 20px;
}

.tour-location {
  font-size: 1rem;
  color: var(--site-grey);
}

.tour-description {
  margin: 10px 0;
  color: var(--site-grey);
}

.tour-dates-container h4 {
  margin-bottom: 0;
}

.tour-dates {
  font-size: 0.9rem;
  color: var(--site-grey);
  /* margin-bottom: 20px; */
  margin-top: 0;
  display: flex;
  flex-direction: row;
}

.spaces-limited {
  color: var(--accent-color);
  font-style: italic;
  margin-left: 4px;
}

.tour-images {
  display: flex;
  gap: 10px;
  overflow-x: auto;
}

.tour-thumbnail {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  object-fit: cover;
  justify-content: centre;
  cursor: pointer;
}

.tour-button-container button {
  min-width: 100%;
}

/* Gallery Styles */
.gallery-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.gallery-content {
  position: relative;
  max-width: 80%;
  max-height: 80%;
}

.expanded-image {
  width: 100%;
  height: auto;
}

.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  cursor: pointer;
  padding: 10px;
}

.nav-button:hover {
  background-color: rgba(255, 255, 255, 1);
}

.nav-button:first-of-type {
  left: 10px;
}

.nav-button:last-of-type {
  right: 0;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  cursor: pointer;
  padding: 10px;
}

.close-button:hover {
  background-color: rgba(255, 255, 255, 1);
}

.price-container {
  display: flex;
  align-items: center;
}

.currency-dropdown {
  margin: 0 5px;
  border: 1px solid #ccc;
  padding: 1px;
  border-radius: 4px;
  font-size: 16px;
  color: var(--site-grey);
  cursor: pointer;
  width: 100%;
  max-width: 200px;
  background-color: var(--site-white);
}

.currency-dropdown option {
  padding: 10px;
  background-color: var(--site-white);
  color: #333;
  font-size: 14px;
  border: none;
}

.base-currency {
  margin-left: 5px;
}

@media (min-width: 768px) {
  .tour-details-container,
  .tour-list {
    margin: 20px;
  }
}
/* Desktop Styles */
@media (min-width: 1025px) {
  .tour-card {
    flex-direction: row;
    max-width: auto;
    margin: 20px;
  }

  .tour-image {
    width: 40%;
    height: auto;
  }

  .tour-info {
    width: 60%;
    padding: 20px 40px;
    max-height: 800px; /* Adjust to your desired max height */
    overflow-y: auto; /* Adds a scrollbar if the content overflows */
  }

  .nav-button {
    position: relative;
  }

  .gallery-content {
    position: relative;
    max-width: 80%; /* Set the maximum width */
    max-height: 80%; /* Set the maximum height */
    display: flex; /* Flexbox to center the content */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Center items horizontally */
  }

  .expanded-image {
    width: 100%; /* Full width of the container */
    height: auto; /* Maintain aspect ratio */
    max-width: 100%; /* Ensure it doesn’t exceed the container */
    max-height: 100%; /* Ensure it doesn’t exceed the container */
    object-fit: cover; /* Cover the area while maintaining aspect ratio */
  }
}

/* Tabs Container */
.tabs-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

/* Tabs Navigation */
.tabs {
  display: flex;
  flex-wrap: wrap; /* Allows tabs to stack */
  gap: 4px; /* Adds spacing between stacked tabs */
  margin: 10px 0px;
  border-bottom: none;
}

.tab-button {
  flex: 1 1 100%; /* Each tab takes full width on mobile */
  text-align: center;
  padding: 10px;
  cursor: pointer;
  color: var(--site-white);
  background-color: var(--primary-brand-color);
  border: 1px solid var(--site-grey-light);
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease;
  padding: 8px;
}

.tab-button:hover {
  background-color: var(--secondary-brand-color);
  color: var(--site-white);
}

.tab-b:not(.active) {
  display: block; /* Keep inactive tabs visible */
}

.tab.active {
  display: none; /* Hide the active tab */
}

/* Tab Content */
.tab-content {
  display: none;
  padding: 20px;
  color: var(--site-grey);
}

.tab-content.active {
  display: block;
}

/* Desktop Styles */
@media (min-width: 768px) {
  .tabs {
    flex-wrap: nowrap; /* Prevent stacking on larger screens */
    gap: 4px; /* Remove spacing between tabs */
  }

  .tab {
    flex: 0 0 auto; /* Tabs shrink to fit content */
    margin-right: 10px; /* Adds spacing between inline tabs */
    padding: 15px 20px;
    font-size: 1.1rem;
    border-radius: 0; /* Square edges for desktop */
    border-bottom: 2px solid transparent;
  }

  .tab.active {
    display: block; /* Show active tab */
    border-bottom: 3px solid var(--secondary-brand-color); /* Highlight active tab */
    font-weight: bold;
    color: var(--secondary-brand-color);
  }

  .tab:hover {
    background-color: transparent;
    color: var(--site-grey-dark);
  }
}

@media (min-width: 1400px) {
  .tour-details-container,
  .tour-list {
    margin: 20px 160px;
  }
}
