/* Mobile-first base styles */
.gallery-grid {
  padding-top: 10px;
  display: grid;
  grid-template-columns: 1fr; /* 1 column for small mobile screens by default */
}

.gallery-item {
  overflow: hidden; /* Ensure image fits within the grid container */
  position: relative;
}

.grid-image {
  width: 100%;
  height: auto;
  object-fit: contain; /* Ensure images fit properly on mobile */
  border-radius: 8px;
}

/* For larger screens (Tablet/Small Desktop) - max-width 768px */
@media (min-width: 768px) {
  .gallery-grid {
    gap: 10px; /* Space between items */
    grid-template-columns: repeat(
      2,
      1fr
    ); /* 2 columns for tablet or larger screens */
  }

  .grid-image {
    object-fit: cover; /* Ensure images maintain aspect ratio while filling the container */
  }
}

/* For even larger screens (Large Desktop) - max-width 1024px */
@media (min-width: 1024px) {
  .gallery-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for large desktops */
  }

  .grid-image {
    object-fit: cover; /* Ensure images maintain aspect ratio while filling the container */
  }
}

/* For expanded image in the modal */
.expanded-image {
  max-width: 95vw;
  max-height: 60vh;
}
