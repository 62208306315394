.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: 20px;
  min-height: 80vh;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.2); /* Light grey */
  border-top: 4px solid #66b2b2; /* Your teal color */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.loading-message {
  margin-top: 10px;
  font-size: 16px;
  color: var(--site-white);
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
