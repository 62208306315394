.contact-us-page {
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-us-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1100px;
  flex-direction: column;
}

.contact-us-form {
  background-color: var(--site-white);
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px; /* Limit form width on larger screens */
}

.form-elements {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.span-terms {
  font-size: 0.8rem;
}

input,
textarea {
  width: 90%;
  padding: 12px;
  margin: 8px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: var(--site-grey);
}

textarea {
  min-height: 150px;
}

.error {
  color: var(--error-color);
  font-size: 0.9rem;
}

/* Thank you message styling */
.thank-you-message {
  padding: 20px;
}

.thank-you-message h1 {
  text-align: center;
  color: var(--site-white);
}

.thank-you-message h3 {
  text-align: center;
  color: var(--site-white);
}

/* Subscribe label style */
label {
  display: flex;
  align-items: center;
  gap: 10px;
}

label input[type="checkbox"] {
  margin-right: 10px; /* Space between checkbox and label text */
}

/* Text colors */
body,
.contact-us-form,
.contact-us-form p,
.contact-us-form h1,
.contact-us-form h3,
.contact-us-form label {
  color: var(--site-grey);
}

/* Image Styling */
.image-column img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.label-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 0 auto; /* Center the container itself */
}

.label-container input[type="checkbox"] {
  margin-right: 0;
  vertical-align: middle;
  width: auto;
}

.label-container span {
  white-space: nowrap;
  line-height: normal;
}

/* Responsive Adjustments for Larger Screens */
@media (min-width: 768px) {
  .contact-us-container {
    padding: 50px; /* Add more space around the form */
  }
  .label-container input[type="checkbox"] {
    margin-right: 4px; /* Smaller gap for larger screens */
  }
  .label-container span {
    font-size: 1rem; /* Normal text size for desktop */
  }
}
