/* Mobile First Styles */
.footer-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f4f4f4;
}

.footer-container h3 {
  font-size: 1rem;
  margin-bottom: 10px;
}

.footer-newsletter form {
  display: flex;
  flex-direction: column;
}

.footer-newsletter input {
  max-width: 480px;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.footer-newsletter button {
  padding: 10px;
  background-color: var(--secondary-brand-color);
  color: var(--site-white);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.footer-contact {
  margin: 0px auto;
}

.footer-contact p {
  font-size: 1rem;
  margin: 5px 0px;
  font-size: 00.9rem;
}

.footer-social {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.footer-legal {
  text-align: center;
  font-size: 0.9rem;
  margin-top: 20px;
}

.footer-legal a {
  color: var(--site-white);
  text-decoration: none;
}

.footer-legal a:hover {
  text-decoration: underline;
  color: var(--secondary-brand-color);
}

/* Large screens: Desktop/Tablets */
@media (min-width: 768px) {
  .footer-container {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 40px;
  }

  /* Side by side layout for Contact and Newsletter */
  .footer-newsletter,
  .footer-contact {
    width: 48%; /* Make each section take up roughly half the width */
  }

  /* Ensuring the content in the footer is aligned */
  .footer-newsletter,
  .footer-contact {
    margin-bottom: 0;
  }
}

/* Large screens: Desktop */
@media (min-width: 1024px) {
  .footer-container {
    padding: 60px;
  }

  /* Legal section at the bottom, centered */
  .footer-legal {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
}
